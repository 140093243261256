<div class="row">
  <div class="col-md-12">

    <app-card>
      <div class="row justify-content-center" *ngIf="apps">
        <div class="col-md-12">
          <h2 class="mb-4 text-center">All applications</h2>
          <div class="table-responsive">
          <table class="table  table-borderless">
            <tr>
              <th></th>
              <th>Application reference</th>
              <th>Applied on</th>
              <th>Status</th>
            </tr>
            <ng-container *ngFor="let application of applications.content">
              
              <tr *ngIf="(application.status !== 'NotStarted' && application.applicationType !== 'Automated') ||
              (application.applicationType === 'Automated' &&
               application.status === 'Rejected' ||
                application.status === 'ProvisionallyFunded' ||
                application.status === 'RegistrationReceived')">
              <td><span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x icon-background-sm"></i>
                  <i class="fa fa-flag fa-stack-1x icon-color-sm"></i>
                </span></td>
              <td *ngIf="application.status !=='NotStarted'">{{application.reference}}</td>
              <td *ngIf="application.status ==='NotStarted'"></td>
              <td>{{application.createDate | date :'medium'}}</td>
              <td>
                {{getStatus(application)}}
                <span class="text-muted" *ngIf="application.fundingCondition">{{application?.fundingCondition}}</span>
                <span class="text-muted" *ngIf="application.status==='Rejected'">
<!--                  {{application?.rejectReason?.description}}-->
                  <p *ngFor="let rejectReason of application?.rejectReasons">
                    {{rejectReason.description}}
                    <span class="badge bg-primary" *ngIf="rejectReason.ordinal===2 ||rejectReason.ordinal===11 || rejectReason.ordinal===12">
                      Not Appealable
                    </span>
                    <span class="badge bg-primary" *ngIf="rejectReason.ordinal!==2 && rejectReason.ordinal!==11 && rejectReason.ordinal!==12 && !application.canAppeal">
                      {{application.appealClosedReason}}
                    </span>
                  </p>
                  <ul *ngIf="application.canAppeal">
                    <li>
                      You have <span class="badge bg-primary">{{application.appealableWindowsInDaysLeft |  number : '2.0-1'}}</span> days to appeal the outcome
                    </li>
                  </ul>


                </span>
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 text-start">
                    <button class="btn btn-primary btn-outline-dark" *ngIf="application.status === 'Rejected'" [disabled]="!application.canAppeal" type="button" (click)="openAppeal(application)">Appeal</button>
                    <button class="btn btn-primary btn-outline-dark" *ngIf="application.loanEligible == true" type="button" (click)="applyForLoan(application)">Apply for a loan</button>
                    <button class="btn btn-primary  btn-outline-dark" *ngIf="application.hasAnAppeal ===true" type="button" (click)="openTrackAppeal(application)">Track Appeal</button>
                    <button class="btn btn-primary  btn-outline-dark" *ngIf="application.canRequestDisabilityChange ===true" type="button" (click)="disabilityChangeRequest(application)">Request update on my disability</button>

<!--                    <button class="btn btn-primary  btn-outline-dark" *ngIf="application.canRequestChangeFunder===true" type="button" (click)="openReason(application)">Change Funder</button>-->
                  </div>
                  <div class="col-md-6 text-start">
                    <button class="btn btn-primary" type="button" (click)="openTrack(application)">Track application</button>
                  </div>
                </div>
              </td>
            </tr>
            </ng-container>
          </table>
          </div>
<!--          <div class="row">-->
<!--            <div class="col-md-6 text-end" >-->
<!--              <button *ngIf="application.status=='Rejected'" class="btn btn-primary btn-outline-dark" type="button" (click)="openAppeal()">Appeals</button>-->
<!--            </div>-->
<!--            <div class="col-md-6 text-start">-->
<!--              <button class="btn btn-primary" type="button" (click)="openTrack()">Track application</button>-->

<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="appeal">
        <div class="col-md-11">
          <a (click)="openApps()" class="float-start alt pt-2" style="text-decoration:none"><i class="fa fa-chevron-left"></i> Application Tracker</a>
          <h2 class="mb-4 text-center">Application Appeal</h2>
          <app-appeal [application]="application"></app-appeal>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="withdraw">
        <div class="col-md-11">
          <a (click)="openApps()" class="float-start alt pt-2" style="text-decoration:none"><i class="fa fa-chevron-left"></i> Application Tracker</a>
          <app-withdraw-confirm [application]="application"  (closeWithdraw)="openTrack(application)"> </app-withdraw-confirm>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="withdrawAppeal">
        <div class="col-md-11">
          <a (click)="openApps()" class="float-start alt pt-2" style="text-decoration:none"><i class="fa fa-chevron-left"></i> Application Tracker</a>
          <app-withdraw-appeal [appeal]="appealData" (closeWithdraw)="openTrackAppeal(application)"> </app-withdraw-appeal>
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="track">
        <div class="col-md-11">
          <a (click)="openApps()" class="float-start alt pt-2" style="text-decoration:none"><i class="fa fa-chevron-left"></i> Application Tracker</a>
          <h2 class="mb-4 text-center">Application details</h2>
        </div>
      </div>
      <app-track-application (withdrawApplication)="openWithdraw()" [applicationEvents]="applicationEvents" [application]="application" *ngIf="track"></app-track-application>
      <app-track-appeal (withdrawAppeal)="openWithdrawAppeal($event)" (reOpenWithdrawanAppeal)="openReOpenWithdrawnAppeal($event)" (reProcessAppealEvent)="requestAppealReprocess($event)" [application]="application" *ngIf="trackApeal===true"></app-track-appeal>
      <app-re-open-appeal [appeal]="appealData" *ngIf="reopenWithdrawnApeal===true" (closeWithdraw)="openTrackAppeal(application)"></app-re-open-appeal>
      <app-request-appeal-reprocess  [appeal]="appealData" *ngIf="requestAppealReProcess===true" (closeWithdraw)="openTrackAppeal(application)"></app-request-appeal-reprocess>


    </app-card>
  </div>
</div>
<!-- Main Modal -->
<div id="reasonModal" class="modal fade " xmlns="http://www.w3.org/1999/html">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Select a new funder type</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
      </div>
      <div class="modal-body">
        <div class="input-group mb-3">
        <select appSubFunder class="form-control"  placeholder="Funder" name="funder" [(ngModel)]="application.subFunder" required></select>
          <div class="invalid-feedback">
            Funder is required
          </div>
        </div>

        <div class="input-group mb-3" *ngIf="isDisability()">
          <label for="formFileSm" class="form-label">Disability Annexure</label>
          <input required [(ngModel)]="application.disabilityAnnexure" appFileUpload   name="disabilityAnnexure" accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
          <div class="invalid-feedback">
            Disability Annexure is required
          </div>
          <small class="ps-3" *ngIf="!application.disabilityAnnexure">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="application.disabilityAnnexure">{{application.disabilityAnnexure.name}}</small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmSelection()">Confirm</button>
      </div>
    </div>
  </div>
</div>

<!-- Acknowledgment Modal -->
<div id="confirm" class="modal fade " xmlns="http://www.w3.org/1999/html">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Current Funder Type</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
      </div>
      <div class="modal-body">
        <p>Please confirm that you understand the application will be re-evaluated for financial eligibility when changing funder</p>


        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="termsCheckbox" [(ngModel)]="termsAccepted" required>
          <label class="form-check-label" for="termsCheckbox">Read and understood T&C's.
            <span class="text-end">
            <a style="color:red"
               routerLink="/terms-conditions/Registration"
               target="_blank">View T's&C's</a>
          </span>
          </label>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirmAcknowledgment()" [disabled]="!termsAccepted">Confirm</button>
      </div>
    </div>
  </div>
</div>
