import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {Application} from '../applications/application/application';
import {ApplicationsService} from '../applications/applications.service';
import {ApplicationEvent} from '../applications/application/application.event';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import * as _ from 'lodash';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Appeal} from './appeal/appeal';
import {LoanComponent} from '../loans/loan/loan.component';
import {
  DisabilityChangeRequestComponent
} from '../disability-change-requests/disability-change-request/disability-change-request.component';
import {Attachment} from '../core/models';
declare var bootbox: any;
declare var $: any;
@Pipe ({
  name : 'test'
})
export class ApplicationPipe implements PipeTransform {
  transform(args : Array<Application>) : any{

  }
}
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class ApplicationsComponent implements OnInit {
  appeal: boolean;
  appealData: Appeal;
  track: boolean;
  trackApeal: boolean;
  reopenWithdrawnApeal: boolean;
  withdraw: boolean;
  withdrawAppeal: boolean;
  requestAppealReProcess: boolean;
  applications: Results<Application> = new Results<Application>();
  application: Application = new Application();
  applicationEvents = new Array<ApplicationEvent>();
  apps = true;
  selectedOption: string;
  termsAccepted: boolean;
  
  @BlockUI() blockUI: NgBlockUI;

  constructor(public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar, private applicationServices: ApplicationsService,
              protected gaService: GoogleAnalyticsService,public modalService: NgbModal) {
  }

  search() {
    this.blockUI.start();
    this.applicationServices.search(1, 10, 'DESC', 'createDate', '').subscribe((applications: Results<Application>) => {
      this.applications = applications;
      this.blockUI.stop();
      this.applications.content.forEach((app: Application) => {
        this.application = app;
      });
    }, (error: HttpErrorResponse) => {

      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }

  ngOnInit() {

    this.search();
  }

  openAppeal(application: Application) {
    this.application = application;
    this.appeal = true;
    this.track = false;
    this.apps = false;
    this.trackApeal = false;
    this.withdrawAppeal = false;
    this.withdraw = false;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = false;
  }

  applyForLoan(application: Application) {
    const modalRef = this.modalService.open(LoanComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.application = application;
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

  disabilityChangeRequest(application: Application) {

    const modalRef = this.modalService.open(DisabilityChangeRequestComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.application = application;
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

  openTrackAppeal(application: Application) {
    this.application = application;
    this.trackApeal = true;
    this.appeal = false;
    this.track = false;
    this.apps = false;
    this.withdraw = false;
    this.withdrawAppeal = false;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = false;
  }

  getStatus(application: Application) {

    // if(application.reference.startsWith('MCS') || application.user.studentType==='Continuing' || application.applicationType==='Hybrid') {
    //     return 'Submitted';
    // }else if(application.isImmediatelyFunded===true  || (application.sassaFunded===true && application.studentType==='FTEN')) {
    //   return 'Provisionally Funded';
    // }
    if (application.status === 'NotStarted') {
      return '';
    } else if(application.status ==='ProvisionallyFunded') {
      return 'Funded subject to registration and availability of funds';
    }else if(application.status ==='Withdrawn') {
      return 'Cancelled';
    }else if(application.status ==='Filtering' || application.status ==='Validation') {
      return 'Application in progress';
    }else if(application.status ==='AwaitingEvaluation') {
      return 'Verification';
    }else if(application.status ==='ApplicationInProgress') {
      return 'Requesting financial and academic data';
    }
    return _.startCase(application.status);
  }

  openTrack(application: Application) {

    this.application = application;
    this.applicationServices.getApplicationDetails(application.id).subscribe((applicationEvents: Array<ApplicationEvent>) => {
      this.applicationEvents = applicationEvents;
    });
    this.appeal = false;
    this.trackApeal = false;
    this.track = true;
    this.apps = false;
    this.withdraw = false;
    this.withdrawAppeal = false;
    this.requestAppealReProcess = false;
  }

  openApps() {
    this.trackApeal = false;
    this.appeal = false;
    this.track = false;
    this.apps = true;
    this.withdraw = false;
    this.withdrawAppeal = false;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = false;
  }

  openWithdraw() {
    this.trackApeal = false;
    this.appeal = false;
    this.track = false;
    this.apps = false;
    this.withdraw = true;
    this.withdrawAppeal = false;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = false;
  }

  openWithdrawAppeal($event) {

    this.appealData = $event;
    this.trackApeal = false;
    this.appeal = false;
    this.track = false;
    this.apps = false;
    this.withdraw = false;
    this.withdrawAppeal = true;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = false;
  }

  openReOpenWithdrawnAppeal($event) {
    this.appealData = $event;
    this.trackApeal = false;
    this.appeal = false;
    this.track = false;
    this.apps = false;
    this.withdraw = false;
    this.withdrawAppeal = false;
    this.reopenWithdrawnApeal = true;
    this.requestAppealReProcess = false;
  }

  requestAppealReprocess($event) {
    this.appealData = $event;
    this.trackApeal = false;
    this.appeal = false;
    this.track = false;
    this.apps = false;
    this.withdraw = false;
    this.withdrawAppeal = false;
    this.reopenWithdrawnApeal = false;
    this.requestAppealReProcess = true;
  }


  dismissModal() {
    $('#reasonModal').modal('hide');
    $('#confirm').modal('hide');
  }


  confirmSelection() {

    $('#reasonModal').modal('hide');
    $('#confirm').modal('show');
  }

  openReason(application: Application) {
    this.application = application;
    $('#reasonModal').modal('show');
  }

  isDisability() {
    return this.application?.subFunder?.name?.toLowerCase().indexOf('disability') > -1;
  }

  confirmAcknowledgment() {

    if ((this.application?.disabilityAnnexure?.file instanceof Blob)) {
      this.applicationServices.upload(this.application.disabilityAnnexure.file).subscribe((attachment: Attachment)=>{
        if(attachment) {
          this.application.disabilityAnnexure = attachment;
          this.updated();
        }
      })
    } else {
      this.updated();
    }

  }

  updated() {

    this.blockUI.start();
    this.applicationServices.updateFunder(this.application).subscribe((applicatiom: Application)=>{
      this.blockUI.stop();
      $('#reasonModal').modal('hide');
      $('#confirm').modal('hide');
      this.search();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
    $('#reasonModal').modal('hide');
    $('#confirm').modal('hide');

  }
}
