import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';
import {AppComponent} from './app.component';
import {LayoutComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavLogoComponent} from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ConfigServices} from './core/config';
import {InterceptService} from './core/intercept.service';
import {CookieService} from 'ngx-cookie-service';
import {AutocompleteComponent} from './core/google-places.component';
import {BlockUIModule} from 'ng-block-ui';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {QueriesComponent} from './queries/queries.component';
import {QueryComponent} from './queries/query/query.component';
import {TermsConditionsViewComponent} from './terms-conditions-view/terms-conditions-view.component';
import { RecaptchaModule } from 'ng-recaptcha';
import {ThemeService} from 'ng2-charts';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import { LoansComponent } from './loans/loans.component';
import { LoanComponent } from './loans/loan/loan.component';
import { SignDocumentComponent } from './sign-document/sign-document.component';
import { BankAccountDetailsModule } from './profile/bank-account-details/bank-account-details.module';
import { MatDialogModule} from '@angular/material/dialog';
import { AddressDialogComponent } from './authentication/address-dialog/address-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AuthComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    ToggleFullScreenDirective,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    AutocompleteComponent,
    QueriesComponent,
    QueryComponent,
    TermsConditionsViewComponent,
    LoansComponent,
    LoanComponent,
    SignDocumentComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    BlockUIModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-DW0XFKHC9V'),
    NgxGoogleAnalyticsRouterModule,
    BankAccountDetailsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    NgbActiveModal,
    NavigationItem,
    ConfigServices,
    CookieService,
    ThemeService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }],
    exports: [

    ],
  bootstrap: [AppComponent],
  entryComponents:[AddressDialogComponent]
})
export class AppModule {
}
