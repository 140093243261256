

<h2 mat-dialog-title>Confirm Registration</h2>
<mat-dialog-content>
  <p>
    By ticking this box, I hereby confirm that the registration data being
    uploaded has been thoroughly validated in accordance with the NSFAS funding
    criteria and requirements. Furthermore, I affirm that the data is accurate,
    complete, and fully compliant.
  </p>
  <div class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      id="termsCheckbox"
      [(ngModel)]="confirmChecked"
    />
    <label class="form-check-label" for="termsCheckbox">
      I agree to the Terms and Conditions
      <span class="text-end">
      
      </span>
    </label>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-dialog-actions">
  <button mat-button class="btn btn-danger btn-lg btn-block" (click)="onCancel()">Cancel</button>
  <button mat-button class="btn btn-info btn-lg btn-block" [disabled]="!confirmChecked" (click)="onConfirm()">
    Confirm
  </button>
</mat-dialog-actions>

