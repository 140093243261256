<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3">NSFAS funding application</h2>
    <h5 class="mb-4">Make sure you have the following electronic documents</h5>
    <div class="alert alert-light mb-3 text-start text-dark">
      <small>
        <ul>
          <li>Your South African identity document/card</li>
          <li>IDs of parents / spouse / guardian (or death certificate where applicable).</li>
          <li>Court order (Where applicable)</li>
          <li>Proof of income (Where applicable)</li>
          <li>Vulnerable Child (&lt; 18) NSFAS Declaration (Where applicable)</li>
          <li>Marriage certificate (Where applicable)</li>
          <li>Provisional/Firm/Pre-Enrolment Offer Letter</li>
          <li>Disability annexure (Where applicable) </li>
          <li class="text-danger">No affidavits</li>
        </ul>
      </small>
    </div>
    <form (ngSubmit)="onSubmit()" #kinForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

      <p>Are you married?</p>

      <div class="row">
        <div class="col-md-9">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="married" id="marriedNo" [value]="false"
                   [(ngModel)]="applicationsService.application.married"
                   (change)="clear()">
            <label class="cr" for="marriedNo">No</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="married" id="marriedYes" [value]="true"
                   [(ngModel)]="applicationsService.application.married"
                   (change)="clear()">
            <label class="cr" for="marriedYes">Yes</label>
          </div>
        </div>
      </div>


      <div *ngIf="applicationsService.application.married">
        <p>Does your spouse have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saSpouse" id="spouseIDNo" [value]="false"
                     [(ngModel)]="applicationsService.application.saSpouse">
              <label class="cr" for="spouseIDNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saSpouse" id="spouseIDYes" [value]="true"
                     [(ngModel)]="applicationsService.application.saSpouse">
              <label class="cr" for="spouseIDYes">Yes</label>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="applicationsService.application.married == true">
        <p class="text-primary"><strong>Spouse's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" class="form-control" autocomplete="off"
                 [placeholder]="applicationsService.application.saSpouse == true ? 'ID number' : 'Passport number'" name="spouse.idNumber"
                 [(ngModel)]="applicationsService.application.spouse.idNumber" required pattern="[0-9]{13,13}" minlength="13" maxlength="13">
          <div class="invalid-feedback">
           Spouse's {{applicationsService.application.saSpouse == true ? 'ID number' : 'Passport number'}} is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="spouse.firstName" [(ngModel)]="applicationsService.application.spouse.firstName" minlength="3" maxlength="100" required>
          <div class="invalid-feedback">
            Spouse's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="spouse.lastName" [(ngModel)]="applicationsService.application.spouse.lastName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Spouse's Surname is required
          </div>
        </div>
        <!-- <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="spouse.income"
                 [(ngModel)]="applicationsService.application.spouse.income" required>
          <div class="invalid-feedback">
            Spouse's annual gross income is required
          </div>
        </div> -->
      </div>

       <div *ngIf="applicationsService.application.married !==true">
        <p>Are you able to provide the details of your father (Even if he is deceased)?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="fatherDetails" id="fatherDetailsNo" [value]="false"
                     [(ngModel)]="kin.fatherDetails" (click)="clearFather()">
              <label class="cr" for="fatherDetailsNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="fatherDetails" id="fatherDetailsYes" [value]="true"
                     [(ngModel)]="kin.fatherDetails">
              <label class="cr" for="fatherDetailsYes">Yes</label>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="kin.fatherDetails==true && applicationsService.application.married !==true">
        <p>Does your father have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saFather" id="fatherIDNo" [value]="false"
                     [(ngModel)]="applicationsService.application.saFather">
              <label class="cr" for="fatherIDNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saFather" id="fatherIDYes" [value]="true"
                     [(ngModel)]="applicationsService.application.saFather">
              <label class="cr" for="fatherIDYes">Yes</label>
            </div>
          </div>
        </div>



      </div>
      <div *ngIf="kin.fatherDetails==true">
        <p class="text-primary"><strong>Father's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
                 placeholder="{{applicationsService.application.saFather==true?'Id Number ':'Passport'}}" name="father.idNumber"
                 [(ngModel)]="applicationsService.application.father.idNumber" required pattern="[0-9]{13,13}" minlength="13" maxlength="13">
          <div class="invalid-feedback">
            Father's {{applicationsService.application.saFather==true?'Id Number ':'Passport'}} is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="father.firstName" [(ngModel)]="applicationsService.application.father.firstName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Father's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="father.lastName" [(ngModel)]="applicationsService.application.father.lastName"
                 required minlength="3" maxlength="100">

          <div class="invalid-feedback">
            Father's last name is required
          </div>
        </div>
        <!-- <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="father.income"
                 [(ngModel)]="applicationsService.application.father.income" required>
          <div class="invalid-feedback">
            Father's annual gross income
          </div>
        </div> -->
      </div>
      <div *ngIf="applicationsService.application.married !==true">
        <p>Are you able to provide the details of your mother (Even if she is deceased)?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="motherDetails" id="motherDetailsNo" [value]="false"
                     [(ngModel)]="kin.motherDetails" (click)="clearMother()">
              <label class="cr" for="motherDetailsNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="motherDetails" id="motherDetailsYes" [value]="true"
                     [(ngModel)]="kin.motherDetails">
              <label class="cr" for="motherDetailsYes">Yes</label>
            </div>
          </div>
        </div>


      </div>
      <div *ngIf="kin.motherDetails==true && applicationsService.application.married !==true">
        <p>Does your mother have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saMother" id="motherIDNo" [value]="false"
                     [(ngModel)]="applicationsService.application.saMother">
              <label class="cr" for="motherIDNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saMother" id="motherIDYes" [value]="true"
                     [(ngModel)]="applicationsService.application.saMother">
              <label class="cr" for="motherIDYes">Yes</label>
            </div>
          </div>
        </div>


      </div>
      <div *ngIf="kin.motherDetails==true && applicationsService.application.married !==true">
        <p class="text-primary"><strong>Mother's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
                 placeholder="{{applicationsService.application.saMother==true?'Id Number ':'Passport'}}" name="mother.idNumber"
                 [(ngModel)]="applicationsService.application.mother.idNumber" required pattern="[0-9]{13,13}" minlength="13" maxlength="13">
          <div class="invalid-feedback">
            Mother's {{applicationsService.application.saMother==true?'Id Number ':'Passport'}}is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="mother.firstName" [(ngModel)]="applicationsService.application.mother.firstName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Mother's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="mother.lastName" [(ngModel)]="applicationsService.application.mother.lastName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Mother's last name is required
          </div>
        </div>
        <!-- <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="mother.cellphone"
                 [(ngModel)]="applicationsService.application.mother.income" required>
          <div class="invalid-feedback">
            Mother's annual gross income is required
          </div>
        </div> -->
      </div>


      <div *ngIf="applicationsService.application.married !==true">
        <p>Are you able to provide the details of your guardian (Even if they are deceased)?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianDetails" id="guardianDetailsNo" [value]="false"
                     [(ngModel)]="kin.guardianDetails">
              <label class="cr" for="guardianDetailsNo" (click)="clearGuardian()">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianDetails" id="guardianDetailsYes" [value]="true"
                     [(ngModel)]="kin.guardianDetails">
              <label class="cr" for="guardianDetailsYes">Yes</label>
            </div>
          </div>
        </div>


      </div>
      <div
        *ngIf="kin.guardianDetails==true && applicationsService.application.married !==true">
        <p>Does your guardian have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianID" id="guardianIDNo" [value]="false"
                     [(ngModel)]="applicationsService.application.saGuardian">
              <label class="cr" for="guardianIDNo">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianID" id="guardianIDYes" [value]="true"
                     [(ngModel)]="applicationsService.application.saGuardian">
              <label class="cr" for="guardianIDYes">Yes</label>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="kin.guardianDetails == true && applicationsService.application.married!=true">
        <p class="text-primary"><strong>Guardian's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
          placeholder="{{applicationsService.application.saGuardian==true?'Id Number ':'Passport'}}" name="guardian.idNumber"
                 [(ngModel)]="applicationsService.application.guardian.idNumber" required pattern="[0-9]{13,13}" minlength="13" maxlength="13">
          <div class="invalid-feedback">
            Guardian's Id Number is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="guardian.firstName" [(ngModel)]="applicationsService.application.guardian.firstName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Guardian's first name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="guardian.lastName" [(ngModel)]="applicationsService.application.guardian.lastName"
                 required minlength="3" maxlength="100">
          <div class="invalid-feedback">
            Guardian's last name is required
          </div>
        </div>
        <!-- <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="guardian.cellphone"
                 [(ngModel)]="applicationsService.application.guardian.income" required>
          <div class="invalid-feedback">
            Guardian's annual gross income is required
          </div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="d-grid gap-2">

            <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
          </div>

        </div>
        <div class="col-md-6">
          <div class="d-grid gap-2">

            <button class="btn btn-primary btn-lg mb-3" type="submit">Next</button>
          </div>

        </div>
      </div>

    </form>

  </div>

</div>
