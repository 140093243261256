import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TvetUnivesityComponent } from '../tvet-univesity/tvet-univesity.component';
import { ApplicationsService } from 'src/app/applications/applications.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoanBursaryDecisionComponent } from '../loan-bursary-decision/loan-bursary-decision.component';

@Component({
  selector: 'app-continuing-students',
  templateUrl: './continuing-students.component.html',
  styleUrls: ['./continuing-students.component.scss']
})
export class ContinuingStudentsComponent implements OnInit {
  isSubmitted = false;

  constructor(public modalService: NgbModal,private matSnackBar:MatSnackBar, public applicationsService:ApplicationsService, public fb: FormBuilder) { }
  ngOnInit(): void {
    
  }

  /*########### Form ###########*/
  changeReasonForm = this.fb.group({
    changeReason: ['', [Validators.required]]
  })

  // Getter method to access form control
  get myForm() {
    return this.changeReasonForm.get('changeReason');
  }

  // Submit Registration Form
  onSubmit() {
    this.isSubmitted = true;
    if(!this.changeReasonForm.valid) {
      this.matSnackBar.open('Select Reason for re-applying', 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else {
      this.tvetUniversity();
    }
  }
  
  tvetUniversity() {
    this.modalService.dismissAll();
    this.applicationsService.application.changeReason = this.changeReasonForm.get('changeReason').value;
    const modalRef = this.modalService.open(LoanBursaryDecisionComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(() => {
    });
  }
}
